import React from "react"
import { CSSObject, useTheme } from "@emotion/react"
import { Text } from "../ui"
import { AppTheme } from "pitch45-common/theme/app-theme.types"
import Loop45Logo from "../components/logo/logo"
import { textPresets } from "../ui/text/text.presets"
import { semanticSpacing, unitSpacing } from "../theme/spacing"

function getStyles(theme: AppTheme) {
  const styles = {
    CONTAINER: {
      minHeight: "100vh",
      padding: semanticSpacing.horizontal.narrow,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      background: theme.colors.background,
    },

    LOGO: {
      width: "300px",
      paddingBottom: unitSpacing.double,
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

export default function NotFoundPage() {
  const theme = useTheme() as AppTheme
  const styles = getStyles(theme)
  return (
    <div css={styles.CONTAINER}>
      <span css={styles.LOGO}>
        <Loop45Logo size="large" />
      </span>
      <Text preset={textPresets.largeSemibold}>Oops!</Text>
      <Text preset={textPresets.medium}>We couldn't find the requested page.</Text>
    </div>
  )
}
